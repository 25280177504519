import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FormBuilder, FormArray, FormControl } from '@angular/forms';
import { ConsoleService } from '../../services/console/console.service';
import { TnsService } from '../../services/tns/tns.service';
import { SettingService } from '../../services/model/setting/setting.service';
import { ToolsService } from '../../services/tools/tools.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'tns-component',
  templateUrl: './tns.component.html',
  styleUrl: './tns.component.scss'
})
export class TnsComponent implements OnInit {

  env = environment;

  @Input() sidebarContentIsVisible: boolean = false;
  @Output() updateSidebarContentVisibility = new EventEmitter<boolean>();

  visible: boolean = false;
  errorSegments: string[] = [];
  warningSegments: string[] = [];
  fullMessage: string = '';
  messageFormArray: FormArray<FormControl>;

  constructor(
    private fb: FormBuilder,
    private _consoleService: ConsoleService,
    public tnsService: TnsService,
    public settingService: SettingService,
    private _toolsService: ToolsService
  ) {
    this.messageFormArray = this.fb.array([]);
  }

  ngOnInit(): void {
    this.initializeMessageSegments();
  }

  showRejectedModal() {
    this.errorSegments = [];
    this.warningSegments = [];
    this.messageFormArray.clear();

    // Collect errors and warnings as individual segments
    if(this.tnsService?.selectedFile?.lastTrustAndSignClientFiles.events[0].hasOwnProperty('errors')){
      this.tnsService?.selectedFile?.lastTrustAndSignClientFiles.events[0].errors.forEach(error => {
        this.errorSegments.push(this.tnsService.getErrorDescription(error));
      });
    }

    if(this.tnsService?.selectedFile?.lastTrustAndSignClientFiles.events[0].hasOwnProperty('warnings')){
      this.tnsService?.selectedFile?.lastTrustAndSignClientFiles.events[0].warnings.forEach(warning => {
        this.warningSegments.push(this.tnsService.getWarningDescription(warning));
      });
    }

    // Prepare values for markers
    const firstname = this.tnsService?.selectedFile?.customer?.firstname || 'Client';
    const identificationLink = this.env.appUrl + this.tnsService?.selectedFile?.token;
    const blocked_reasons = this.errorSegments.join('\n') + '/n' + this.warningSegments.join('/n');
    // Define values for markers and replace them in the message template
    const values = {
      blocked_reasons,
      firstname,
      identificationLink
    };

    let message = this.settingService.getByKey('sms_trust_and_sign_unblocked');

    // Replace markers and get the full message
    this.fullMessage = this._toolsService.replaceMarkers(message, values);

    // Initialize FormArray with segments of 160 characters each
    this.updateMessageSegments();

    // Set modal visibility to true
    this.tnsService.showModalRejectClientFile = true;
  }

  // Split fullMessage into 160 character segments and initialize FormArray
  initializeMessageSegments() {
    this.updateMessageSegments();
  }

  // Update message segments based on fullMessage changes
  updateMessageSegments() {
    const segments = this._toolsService.splitMessage(this.fullMessage, 160);

    // Clear current FormArray and add new segments
    this.messageFormArray.clear();
    segments.forEach(segment => this.messageFormArray.push(new FormControl(segment)));
  }

  // Handles input in each message segment and limits characters to 160
  onMessageSegmentInput(control: FormControl) {
    const value = control.value;
    if (value.length > 160) {
      control.setValue(value.slice(0, 160));
    }
  }

  // Determine color class for character counter based on length
  getCounterColorClass(length: number): string {
    if (length <= 100) return 'text-black';
    if (length <= 159) return 'text-warning';
    return 'text-danger';
  }

  // Get combined message from the FormArray
  getCombinedMessage(): string[] {
    return this.messageFormArray.value;
  }

  adjustHeight(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = 'auto'; // Réinitialise la hauteur pour calculer la hauteur correcte
    textarea.style.height = textarea.scrollHeight + 'px'; // Ajuste la hauteur en fonction du contenu
  }
}
