import { ApplicationRef, Injectable } from '@angular/core';
import { ApiService } from '../../api/api.service';
import { ConsoleService } from '../../console/console.service';
import { CustomerFile } from '../../../interfaces/customer-file';

import moment from 'moment';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class CustomerFileService {
  customerFile: CustomerFile;
  customerFiles: any;
  filters;

  constructor(
    private _apiService: ApiService,
    private _consoleService: ConsoleService,
    private messageService: MessageService,
    private appRef: ApplicationRef
  ) {
    this.loadFilters(); // Charger les filtres depuis le localStorage ou initialiser avec les valeurs par défaut
  }

  private loadFilters() {
    const storedFilters = localStorage.getItem('customerFileFilters');
    if (storedFilters) {
      this.filters = JSON.parse(storedFilters);
      this._consoleService.debug("CustomerFileService - loadFilters", "b", 'this.filters', this.filters);
    } else {
      this.filters = {
        // 'where': [{"key":"esim","operator":"!=","value":"1","type":"and"},{"key":"phone_line_status_id","operator":"!=","value":"4","type":"and"}],
        'where': [{"key":"esim","operator":"!=","value":"1","type":"and"}],
        'whereDate': [],
        'null': [],
        'notnull': [],
        'pivot': [],
        'simplelike': [],
        'orderby': { key: 'id', direction: 'DESC' },
        'paginate': 1,
        'perpage': 10,
        'page': 1,
      };
      this.saveFilters(); // Sauvegarder les filtres par défaut dans le localStorage
    }
  }

  saveFilters() {
    this._consoleService.debug("CustomerFileService - saveFilters", "b", 'this.filters', this.filters);
    localStorage.setItem('customerFileFilters', JSON.stringify(this.filters));
    this.getAll();
  }

  setCustomerFile(customer: CustomerFile) {
    this.customerFile = customer;
  }

  initCustomerFile() {
    this.customerFile = {
      customerId: 0,
      customerFileId: 0,
      phone: "",
      civility: "",
      lastname: "",
      firstname: "",
      birthdate: "",
      otp_code: "",
      language: "",
      esim: false
    };
    const token = localStorage.getItem('token');
    this._consoleService.debug('CustomerFileService - initCustomerFile', 'b', 'token', token);
    if (token && token.length == 32) {
      this.getCustomerFileByToken(token);
    }
  }

  getCustomerFileByToken(token) {
    this._apiService.get('customer-files/token/' + token).subscribe((data: any) => {
      if (data.data.birthdate) {
        const [datePart] = data.data.birthdate.split('T');
        const [year, month, day] = datePart.split('-');
        data.data.birthdate = `${day}/${month}/${year}`;
      }
      this.setCustomerFile(data.data);
    });
  }

  getAll() {
    this._consoleService.debug("CustomerFileService - getAll", "o", 'this.filters', this.filters);
    this._apiService.get('browse/customer-files?page=' + this.filters.page, this.filters).subscribe((resp: any) => {
      this.customerFiles = resp.data;
      this.customerFiles.data = this.customerFiles.data.map(file => this.prepareCustomerFile(file));
      this._consoleService.debug("HomeComponent - getCustomerFile", "g", 'this.customerFile', this.customerFiles);
    });
  }

  prepareCustomerFile(file) {
    // data.forEach(file => {
      if (file.consent) {
        file.consent = JSON.parse(file.consent);
      }
      if (file && file['trust_and_sign_client_files'] && file['trust_and_sign_client_files'].length > 0) {
        file.lastTrustAndSignClientFiles = file['trust_and_sign_client_files'].slice(-1)[0];
        switch (file.lastTrustAndSignClientFiles.state) {
          case 'PENDING':
            file.lastTrustAndSignClientFiles.stateName = 'EN COURS';
            file.lastTrustAndSignClientFiles.statusColor = 'warning';
            break;
          case 'ACCEPTED':
            if (file.consent) {
              file.lastTrustAndSignClientFiles.state = 'DONE';
              file.lastTrustAndSignClientFiles.stateName = 'TERMINÉ';
              file.lastTrustAndSignClientFiles.statusColor = 'success';
            } else {
              file.lastTrustAndSignClientFiles.stateName = 'ACCEPTÉ';
              file.lastTrustAndSignClientFiles.statusColor = 'primary';
            }
            break;
          case 'SUSPENDED':
            file.lastTrustAndSignClientFiles.stateName = 'À DÉBLOQUER';
            file.lastTrustAndSignClientFiles.statusColor = 'danger';
            break;
          case 'REJECTED':
            file.lastTrustAndSignClientFiles.stateName = 'REJETÉ';
            file.lastTrustAndSignClientFiles.statusColor = 'danger';
            break;
        }
        if (file.lastTrustAndSignClientFiles.events) {
          file.lastTrustAndSignClientFiles.events = JSON.parse(file.lastTrustAndSignClientFiles.events).reverse();
        }
      }
    // });

    return file;
  }

  /**
   * FILTER SECTION
   */

  addWhere(filter) {
    this.filters.where.push(filter);
  }

  addWhereDate(filter) {
    this.filters.whereDate.push(filter);
  }

  addNullOrNotNull(filter) {
    if(filter.value === 'notnull') {
      this.filters.notnull.push(filter);
    } else {
      this.filters.null.push(filter);
    }
  }

  addPivot(filter) {
    this.filters.pivot.push(filter);
  }

  addSimplelike(filter) {
    this.filters.simplelike.push(filter);
  }
  getFilters() {
    return this.filters;
  }

  resetFilters(type = 'where') {
    if(type === 'where') {
      this.filters.where = [];
    } else if(type === 'whereDate') {
      this.filters.whereDate = [];
    } else if(type === 'notnull') {
      this.filters.notnull = [];
      this.filters.null = [];
    } else if(type === 'pivot') {
      this.filters.pivot = [];
    } else if(type === 'simplelike') {
      this.filters.simplelike = [];
    }
  }

  deleteFilter() {
    this.filters.where = [];
    this.filters.whereDate = [];
    this.filters.notnull = [];
    this.filters.null = [];
    this.filters.pivot = [];
    this.filters.simplelike = [];
    this.saveFilters();
  }



  getSimActivationSeverity(file): any {
    const simActivationDate = moment(file['sim_activation_date']);
    const currentDate = moment();
    const daysDifference = currentDate.diff(simActivationDate, 'days');

    if (daysDifference > 30) {
      return 'danger';
    } else if (daysDifference >= 28 && daysDifference <= 30) {
      return 'warning';
    } else {
      return 'success';
    }
  }

  getDaysDifference(file): string {
    const simActivationDate = moment(file['sim_activation_date']);
    const currentDate = moment();
    const daysDifference = currentDate.diff(simActivationDate, 'days');
    return `J+${daysDifference}`;
  }

  updateCust(file, custToUpdate) {
    this._consoleService.debug("CustomerFileService - updateCust", "b", 'file', file);
    if(custToUpdate) {
      this._consoleService.debug("CustomerFileService - updateCust", "b", 'custToUpdate', custToUpdate);
      this.messageService.add({severity:'success', summary:'Synchronisation NOA', detail:'La synchronisation avec NOA a été effectuée', life: 5000});

    this.appRef.tick();
    } else {
      this.messageService.add({severity:'danger', summary:'Synchronisation NOA', detail:'Veuillez saisir un CUST valide', life: 5000});
    }
  }
}
