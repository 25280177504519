import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';
import { ToolsService } from '../tools/tools.service';
import { environment } from '../../../../environments/environment';
import { ConsoleService } from '../console/console.service';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {
  env = environment;
  key: string;
  keysArray = new Map();

  constructor(
    private _cookieService: CookieService,
    private _consoleService: ConsoleService,
    private _toolsService: ToolsService,
  ) {
    this.key = "789787987";
  }

  /* GETTER */
  get(name: string) {
    name = this._toolsService.encryptUsingAES256(name);
    const encryptedCookie = this._cookieService.get(name);
    if (encryptedCookie) {
      const bytes = CryptoJS.AES.decrypt(encryptedCookie, this.key);
      return bytes.toString(CryptoJS.enc.Utf8);
    }
    return null;
  }

  /* SETTER */
  // set(name: string, data: any, path = '/', domain = this.env.domain) {
  //   let tmp = name;
  //   name = this._toolsService.encryptUsingAES256(name);
  //   this.keysArray.set(tmp, name);

  //   const encryptedData = CryptoJS.AES.encrypt(data, this.key).toString();
  //   const isDev = 'localhost';

  //   this._consoleService.debug("set", "r", name, data);
  //   this._cookieService.set(name, encryptedData, 90, path, domain, !isDev, isDev ? undefined : "None");
  // }

  set(name: string, data: any, path = '/', domain = this.env.domain) {
    let tmp = name;
    name = this._toolsService.encryptUsingAES256(name);
    this.keysArray.set(tmp, name);
    const encryptedData = CryptoJS.AES.encrypt(data, this.key).toString();
    this._consoleService.debug("set", "r", name, data);
    this._cookieService.set(name, encryptedData, 90, path, domain, true, "Strict");
  }

  /* CHECK */
  check(name: string) {
    name = this._toolsService.encryptUsingAES256(name);
    return this._cookieService.check(name);
  }

  /* GETALL */
  getAll() {
    return this._cookieService.getAll();
  }

  /* CHECKLIST FROM AN ARRAY */
  checkList(list: string[]) {
    let response: Boolean[] = [];
    list.forEach((element: string) => {
      response.push(this.check(element));
    });
    return response;
  }

  /* CLEAR */
  clear() {
    return this._cookieService.deleteAll('/', this.env.domain);
  }
}
