import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Supprimez BrowserAnimationsModule de ce fichier pour éviter le double chargement.

import { SidebarComponent } from './components/sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { PrimengModule } from './primeng.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { TopbarComponent } from './components/topbar/topbar.component';
import { EventComponent } from './components/tns/event/event.component';
import { IdentityComponent } from './components/tns/identity/identity.component';
import { TnsComponent } from './components/tns/tns.component';

@NgModule({
  declarations: [
    SidebarComponent,
    TopbarComponent,
    TnsComponent,
    IdentityComponent,
    EventComponent,
  ],
  imports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    PrimengModule,
    PdfViewerModule
  ],
  exports:[
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PrimengModule,
    SidebarComponent,
    TopbarComponent,
    TnsComponent,
    IdentityComponent,
    EventComponent,
    PdfViewerModule
  ]
})
export class SharedModule { }
