<div class="layout-topbar">
  <div class="topbar-start">
    <button type="button" class="topbar-menubutton p-link p-trigger" (click)="sidebarService.toggle()"><i class="pi pi-bars"></i></button>
    <span class="topbar-title ms-3">{{ currentTitle }}</span>
  </div>
  <div class="topbar-end">
    <ul class="topbar-menu">
      <li class="topbar-profile">
        <div class="dropdown">
          <a class="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="true">
            <span class="avatar bg-secondary"><i class="fa-regular fa-user"></i></span>
          </a>
          <ul class="dropdown-menu dropdown-menu-dark text-small" id="dropdownUser1">
              <li><a class="dropdown-item" href="#">Profil</a></li>
              <li>
                  <hr class="dropdown-divider">
              </li>
              <li><a class="dropdown-item" (click)="logout()">Déconnexion</a></li>
          </ul>
      </div>
      </li>
    </ul>
  </div>
</div>
