import { Component, Input } from '@angular/core';
import { TnsService } from '../../../services/tns/tns.service';

@Component({
  selector: 'tns-event',
  templateUrl: './event.component.html',
  styleUrl: './event.component.scss'
})
export class EventComponent {

  @Input() event: any;
  @Input() customer: any;

  constructor(
    public tnsService: TnsService
  ) { }

}
