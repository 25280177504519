import { Component } from '@angular/core';
import { TnsService } from '../../../services/tns/tns.service';

@Component({
  selector: 'tns-identity',
  templateUrl: './identity.component.html',
  styleUrl: './identity.component.scss'
})
export class IdentityComponent {

  constructor(
    public tnsService: TnsService
  ){}
}
