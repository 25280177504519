import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api/api.service';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';
import { SidebarService } from '../../services/sidebar/sidebar.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent implements OnInit {

  user;

  constructor(
    private _apiService: ApiService,
    private _authService: AuthService,
    private _router: Router,
    public sidebarService: SidebarService
  ) {}

  ngOnInit() {
    this.user = this._authService.authenticated();
  }

  logout(){
    this._authService.logout();
  }

  onSidebarVisibleChange(newValue) {
    if (!newValue) {
      this.sidebarService.hide();
    }
  }
}
