
<div class="layout-sidebar" [class.sidebarOpened]="sidebarService.sidebarVisible()">
    <div class="layout-menu-container">
        <div class="sidebar-header">
            <img src="/assets/images/logo-onati-blanc.png" alt="Logo Vini" class="img-fluid" width="80%" style="max-width: 50px;">
        </div>
        <ul class="layout-menu">
          <li class="layout-root-menuitem" routerLinkActive="active">
            <ul>
              <li>
                <a class="p-element p-ripple" (click)="sidebarService.toggle()" [routerLink]="'/dashboard'">
                  <i class="layout-menuitem-icon fa-light fa-gauge"></i> <span class="layout-menuitem-text">Tableau de bord</span>
                </a>
              </li>
            </ul>
          </li>
          <li class="layout-root-menuitem" routerLinkActive="active">
            <div class="layout-menuitem-root-text" routerLinkActive="active">
              Dossiers Clients
            </div>
            <ul>
              <li>
                <a class="p-element p-ripple" (click)="sidebarService.toggle()" [routerLink]="'/dossiers'">
                  <i class="layout-menuitem-icon fa-light fa-sim-cards"></i> <span class="layout-menuitem-text">Dossiers</span>
                </a>
              </li>
            </ul>
          </li>
          <li class="layout-root-menuitem" routerLinkActive="active">
            <div class="layout-menuitem-root-text" routerLinkActive="active">
              Utilisateurs
            </div>
            <ul>
              <li>
                <a class="p-element p-ripple" (click)="sidebarService.toggle()" [routerLink]="'/agents'">
                  <i class="layout-menuitem-icon fa-light fa-user-headset"></i> <span class="layout-menuitem-text">Agent</span>
                </a>
              </li>
              <li>
                <a class="p-element p-ripple" (click)="sidebarService.toggle()" [routerLink]="'/partenaires'">
                  <i class="layout-menuitem-icon fa-light fa-user-cowboy"></i> <span class="layout-menuitem-text">Partenaires</span>
                </a>
              </li>
            </ul>
          </li>
          <li class="layout-root-menuitem" routerLinkActive="active">
            <div class="layout-menuitem-root-text" routerLinkActive="active">
              Tirage au sort
            </div>
            <ul>
            </ul>
          </li>
          <li class="layout-root-menuitem" routerLinkActive="active">
            <ul>
              <li>
                <a class="p-element p-ripple" (click)="sidebarService.toggle()" [routerLink]="'/gifts'">
                  <i class="layout-menuitem-icon fa-light fa-gift"></i> <span class="layout-menuitem-text">Lots</span>
                </a>
              </li>
              <li>
                <a class="p-element p-ripple" (click)="sidebarService.toggle()" [routerLink]="'/draw'">
                  <i class="layout-menuitem-icon fa-light fa-gift"></i> <span class="layout-menuitem-text">Tirages</span>
                </a>
              </li>
            </ul>
          </li>
          <li class="layout-root-menuitem" routerLinkActive="active">
            <div class="layout-menuitem-root-text" routerLinkActive="active">
              Réglages
            </div>
            <ul>
            </ul>
          </li>
          <li class="layout-root-menuitem" routerLinkActive="active">
            <ul>
              <li>
                <a class="p-element p-ripple" (click)="sidebarService.toggle()" [routerLink]="'/settings/main'">
                  <i class="layout-menuitem-icon fa-light fa-sliders"></i> <span class="layout-menuitem-text">Général</span>
                </a>
              </li>
              <li>
                <a class="p-element p-ripple" (click)="sidebarService.toggle()" [routerLink]="'/settings/sms-message'">
                  <i class="layout-menuitem-icon fa-light fa-message-sms"></i> <span class="layout-menuitem-text">Sms de relance</span>
                </a>
              </li>
              <li>
                <a class="p-element p-ripple" (click)="sidebarService.toggle()" [routerLink]="'/settings/localization'">
                  <i class="layout-menuitem-icon fa-light fa-message-sms"></i> <span class="layout-menuitem-text">Traduction</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
    </div>
</div>
