import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Columns } from '../shared/models/columns.model';
import { ApiService } from '../shared/services/api/api.service';
import { query } from 'express';
import { AuthService } from '../shared/services/auth/auth.service';
import { Router } from '@angular/router';
import { SidebarService } from '../shared/services/sidebar/sidebar.service';

@Component({
  selector: 'app-bo',
  templateUrl: './bo.component.html',
  styleUrl: './bo.component.scss',
})
export class BoComponent implements OnInit {

  pageIndex = 0;
  filterByStatus = 0;
  sidebarVisible: boolean = true;
  sidebarRightVisible: boolean = false;
  menu!: MenuItem[];
  user;
  constructor(
    private _apiService: ApiService,
    private _authService: AuthService,
    private _router: Router,
    public sidebarService: SidebarService
  ) {}

  ngOnInit() {
    if(!this._authService.authenticated()){
      this._router.navigate(["login"]);
    }
    this.user = this._authService.authenticated();
  }

  onSidebarShow(){}
  onSidebarHide(){}
  switchPage(index: number) {
    this.pageIndex = index;
  }

  customerFile: any = [];
  allCustomerFile: any = [];
  getCustomerFile(){
    this._apiService.get('browse/customer-files').subscribe((resp: any) =>{
      this.allCustomerFile = resp.data;
    })
  }

  goToPage(page){
    this._router.navigate([page]);
  }
}
