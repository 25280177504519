import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ConsoleService } from '../console/console.service';
import { ApiService } from '../api/api.service';
import { CustomerFileService } from '../model/customerFile/customer-file.service';
import { MessageService } from 'primeng/api';
import { ToolsService } from '../tools/tools.service';
import { SettingService } from '../model/setting/setting.service';

interface EventTNS {
  type: string;
  title: string;
}

@Injectable({
  providedIn: 'root'
})
export class TnsService {

  env = environment;
  report: any;
  selectedFile: any;
  selectedFileIndex: number = 0;
  tnsErrors: any = {};
  tnsWarnings: any = {};

  showModalRejectClientFile: boolean = false;

  constructor(
    private http: HttpClient,
    private _consoleService: ConsoleService,
    private _apiService: ApiService,
    private _customerFileService: CustomerFileService,
    private messageService: MessageService,
    private _toolsService: ToolsService,
    private _settingService: SettingService
  ) {
    this.loadTnsErrors();
    this.loadTnsWarnings();
  }

  eventTns: EventTNS[] = [
    { type: 'CREATION', title: 'OUVERTURE' },
    { type: 'CLIENT_FILE_ACCESSED', title: 'ACCÈS' },
    { type: 'GDPR_ACCEPTATION', title: 'RGPD' },
    { type: 'DOCUMENT_SUBMISSION', title: 'IDENTIFICATION' },
    { type: 'UPDATE', title: 'MISE À JOUR' },
    { type: "DOCUMENT_REVIEWED", title: 'REVUE' },
    { type: "UNBLOCKING", title: 'DÉBLOCAGE' },
    { type: "PARTICIPANT_COMPLETION", title: 'SOUMISSION' },
    { type: "FINALIZATION", title: 'FINALISATION' },
    { type: "ACCEPTATION", title: 'ACCEPTATION' },
    { type: "REOPEN", title: 'RE-OUVERTURE' },

  ];

  getEventTitle(eventType): string {

    const event = this.eventTns.find(e => e.type === eventType);
    return event ? event.title : 'Inconnu';

  }

  getReport(): void {
    this._apiService.get('read/trust-and-sign-client-files/report/' + this.selectedFile?.lastTrustAndSignClientFiles?.id).subscribe((data: any) => {
        this.report = JSON.parse(data.data);
        this._consoleService.debug('TnsService - getReport', 'r', 'report', this.report);
        this.checkIfErros();
      },
      (error) => {
        console.error('Erreur lors de la récupération du rapport', error);
        this.updateClientFile();
      }
    );
  }

  checkIfErros(): boolean {
    return this.report?.clientFile.participants[0].identityChecks[0].hasOwnProperty('idDocument') && this.report?.clientFile.participants[0].identityChecks[0].idDocument.errors?.length > 0;
  }

  getTypeMedia(document){
    const extension = document.document_url.split('.').pop()?.toLowerCase();

    if (extension === 'pdf') {
      return 'pdf';
    }

    return 'image';
  }


  getPI(document): string {
    return this.env.uploadPath + 'client-files/' + this.selectedFile?.lastTrustAndSignClientFiles?.uuid + '/' + document.document_url;
  }

  loadTnsErrors(): void {
    const url = '/assets/tns/tns-errors.json';
    this.http.get(url).subscribe(
      (data) => {
        this.tnsErrors = data;
      },
      (error) => {
        console.error('Erreur lors du chargement des erreurs TNS', error);
      }
    );
  }

  getErrorDescription(code: string): string {
    return this.tnsErrors[code] || 'Erreur inconnue';
  }

  loadTnsWarnings(): void {
    const url = '/assets/tns/tns-warnings.json';
    this.http.get(url).subscribe(
      (data) => {
        this.tnsWarnings = data;
      },
      (error) => {
        console.error('Erreur lors du chargement des warnings TNS', error);
      }
    );
  }

  getWarningDescription(code: string): string {
    return this.tnsWarnings[code] || 'Warning inconnu';
  }

  acceptClientFile(): void {

    // Prepare values for markers
    const firstname = this?.selectedFile?.customer?.firstname || 'Client';
    const identificationLink = this.env.appUrl + this?.selectedFile?.token;

    // Define values for markers and replace them in the message template
    const values = {
      firstname,
      identificationLink
    };
    let fullMessage = this._toolsService.replaceMarkers(this._settingService.getByKey('sms_trust_and_sign_accepted'), values);

    const data = {
      user: "e11954cf-ccff-4828-bbf0-354b0bb0b03e",
      message: fullMessage,
      phone: this.selectedFile?.phone
    };

    this._apiService.post('client-files/' + this.selectedFile?.lastTrustAndSignClientFiles?.uuid + '/unblock', data, 'netheos/').subscribe((data: any) => {
        this.updateClientFile();
        this.messageService.add({severity:'success', summary:'Acceptation de la pièce', detail:'La pièce a été acceptée et le SMS a été envoyé', life: 10000});
      },
      (error) => {
        console.error('Erreur lors du déblocage du client file', error);
      }
    );
  }

  rejectClientFile(message: string[]): void {

    let data = {
      user: "e11954cf-ccff-4828-bbf0-354b0bb0b03e",
      reopenedTasks: "1_1",
      message: JSON.stringify(message),
      phone: this.selectedFile?.phone
    };

    this._apiService.post('client-files/' + this.selectedFile?.lastTrustAndSignClientFiles?.uuid + '/reopen', data, 'netheos/').subscribe((data: any) => {
        this.updateClientFile();
        this.messageService.add({severity:'success', summary:'Rejet de la pièce', detail:'La pièce a été rejetée et le SMS a été envoyé', life: 10000});
      },
      (error) => {
        console.error('Erreur lors du déblocage du client file', error);
      }
    );
  }

  updateClientFile(){

    let notifications = [
      {
        "id": 167842,
        "date": "2018-10-31T08:46:02.956Z",
        "event": "TO_UPDATE",
        "clientFileUuid": this.selectedFile?.lastTrustAndSignClientFiles?.uuid,
      }
    ];

    this._apiService.post('netheos/webhook', {notifications: JSON.stringify(notifications)}).subscribe((data) => {
      this.selectedFile = this._customerFileService.prepareCustomerFile(data.data);
      this._customerFileService.customerFiles.data[this.selectedFileIndex] = this.selectedFile;
      this.showModalRejectClientFile = false;
      this.messageService.add({severity:'success', summary:'Mise à jour du dossier', detail:'Les données ont été mises à jour', life: 10000});
    });
  }
}
