import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {

  key;

  // Replace markers in a template
  replaceMarkers(template: string, values: { [key: string]: string }): string {
    return template.replace(/{{(.*?)}}/g, (_, key) => values[key] || '{{' + key + '}}');
  }

  // Split message into segments of specified length (default 160)
  splitMessage(message: string, maxLength: number = 160): string[] {
    const segments: string[] = [];
    let currentIndex = 0;

    while (currentIndex < message.length) {
      let endIndex = currentIndex + maxLength;

      if (endIndex >= message.length) {
        segments.push(message.slice(currentIndex));
        break;
      }

      while (endIndex > currentIndex && message[endIndex] !== ' ') {
        endIndex--;
      }

      if (endIndex === currentIndex) {
        endIndex = currentIndex + maxLength;
      }

      segments.push(message.slice(currentIndex, endIndex).trim());
      currentIndex = endIndex + 1;
    }

    return segments;
  }



  encryptUsingAES256(name) {
    if(!name) return;
    let _key = CryptoJS.enc.Utf8.parse(this.key);
    let _iv = CryptoJS.enc.Utf8.parse(this.key);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(name), _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
    return encrypted.toString();
  }
}
