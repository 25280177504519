import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { CookiesService } from '../cookies/cookies.service';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private _httpClient: HttpClient,
    private _cookieService: CookiesService
  ){
    this.setHeaderOptions();
  }

  // HttpHeaders Options
  headersBasic!: HttpHeaders;
  headersPaginate!: HttpHeaders;

  setHeaderOptions(filters = null, paginate = "0", perpage = "0") {
    this.headersBasic = new HttpHeaders();
    this.headersBasic = this.headersBasic.set('Accept', 'application/json');
    this.headersBasic = this.headersBasic.set('Content-Type', 'application/json');
    this.headersBasic = this.headersBasic.set('X-CSRF-TOKEN', '');
    this.headersBasic = this.headersBasic.set('Authorization', 'Bearer ' + this._cookieService.get('token'));
    this.headersBasic = this.headersBasic.set('filters', JSON.stringify(filters));
  }

  get(url: string, filters: any = null, prefix = 'demat/') {
    this.setHeaderOptions(filters);
    return this._httpClient.get(API_URL + prefix + url, {headers:this.headersBasic});
  }

  paginate(url, per_page = "10", filters = null, prefix = 'demat/') {
    this.setHeaderOptions("1", per_page, filters);
    return this._httpClient.get(API_URL + prefix + url, {headers:this.headersBasic});
  }

  post(url: string, data: any, prefix = 'demat/'): any {
    this.setHeaderOptions();
    return this._httpClient.post(API_URL + prefix + url, data, {headers:this.headersBasic});
  }
}
