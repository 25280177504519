import { Component, OnInit } from '@angular/core';
import { SidebarService } from '../../services/sidebar/sidebar.service';
import { AuthService } from '../../services/auth/auth.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrl: './topbar.component.scss'
})
export class TopbarComponent implements OnInit {

  user: any;
  currentTitle: string;

  constructor(
    public sidebarService: SidebarService,
    private _authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.getRouteTitle(this.route))
      )
      .subscribe((title: string) => {
        this.setTitle(title);
      });
  }

  setTitle(newTitle: string) {
    this.currentTitle = newTitle;
  }

  // Obtenir le titre depuis les données de route
  private getRouteTitle(route: ActivatedRoute): string {
    let child = route.firstChild;
    while (child) {
      if (child.snapshot.data['title']) {
        return child.snapshot.data['title'];
      }
      child = child.firstChild;
    }
    return 'Titre par défaut';
  }

  ngOnInit() {
    this.user = this._authService.authenticated();
  }

  logout(){
    this._authService.logout();
  }
}
