<div class="mb-5">
  <h5>Données saisies</h5>
  <div class="card">
    <div>Nom <b>{{ tnsService.selectedFile?.customer?.lastname }}</b></div>
    <div>Prénom <b>{{ tnsService.selectedFile?.customer?.firstname }}</b></div>
    <div>Date de naissance <b>{{ tnsService.selectedFile?.customer?.birthdate | date:'dd/MM/yyyy' }}</b></div>
  </div>
</div>
<ng-container *ngIf="tnsService.selectedFile?.update_by_partner">
  <h5 style="text-transform: uppercase;">{{ tnsService.selectedFile?.customer['identity_documents'][tnsService.selectedFile?.customer['identity_documents'].length - 1]['document_type'] }}</h5>
  <div class="card">
    <div class="row">
      <div class="col-12">
        Numéro de pièce : <br/>
        <b>{{ tnsService.selectedFile?.customer['identity_documents'][tnsService.selectedFile?.customer['identity_documents'].length - 1]['document_number'] }}</b>
      </div>
        <div class="col-12">
          <p-image *ngIf="tnsService.selectedFile?.customer['identity_documents'][tnsService.selectedFile?.customer['identity_documents'].length - 1]['document_url'] && tnsService.getTypeMedia(tnsService.selectedFile?.customer['identity_documents'][tnsService.selectedFile?.customer['identity_documents'].length - 1]) !== 'pdf'" [src]="tnsService.getPI(tnsService.selectedFile?.customer['identity_documents'][tnsService.selectedFile?.customer['identity_documents'].length - 1])" width="100%"  [preview]="true" />
        </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="tnsService?.report?.clientFile?.participants[0].identityChecks">
  <h5 style="text-transform: uppercase;">{{ tnsService?.report?.clientFile?.participants[0]?.identityChecks[0]?.idDocument?.label }}</h5>
  <div class="card">
    <div class="row">
      <div class="col-12">
        <div>Nom <b>{{ tnsService?.report?.clientFile?.participants[0].identityChecks[0].idDocument?.data?.informations?.holder?.birthName }}</b></div>
        <div>Prénom <b>{{ tnsService?.report?.clientFile?.participants[0].identityChecks[0].idDocument?.data?.informations?.holder?.firstName }}</b></div>
        <div>Date de naissance <b>{{ tnsService?.report?.clientFile?.participants[0].identityChecks[0].idDocument?.data?.informations?.holder?.birthDate | date:'dd/MM/yyyy' }}</b></div>
      </div>
      <ng-container *ngFor="let identityDocument of tnsService.selectedFile?.lastTrustAndSignClientFiles?.identity_document">
        Numéro de pièce : <br/>
        <div class="col-12" *ngIf="identityDocument.document_type === 'PASSPORT'">
          <div>Ligne 1<br/><b>{{ tnsService?.report?.clientFile?.participants[0].identityChecks[0].idDocument?.data?.mrz?.line1 }}</b></div>
          <div>Ligne 2<br/><b>{{ tnsService?.report?.clientFile?.participants[0].identityChecks[0].idDocument?.data?.mrz?.line2 }}</b></div>
        </div>
        <div class="col-12">
          <pdf-viewer *ngIf="tnsService.getTypeMedia(identityDocument) === 'pdf'"
            [src]="tnsService.getPI(identityDocument)"
            [rotation]="0"
            [original-size]="false"
            [show-all]="true"
            [fit-to-page]="false"
            [zoom]="1"
            [zoom-scale]="'page-width'"
            [stick-to-page]="false"
            [render-text]="true"
            [external-link-target]="'blank'"
            [autoresize]="true"
            [show-borders]="false"
            style="width: 100%; height: 100%;"
          ></pdf-viewer>
          <p-image *ngIf="tnsService.getTypeMedia(identityDocument) !== 'pdf'" [src]="tnsService.getPI(identityDocument)" width="100%"  [preview]="true" />
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
